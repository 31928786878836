<template>
    <router-view/>
    <div class="icp">
      {{`© 2024 - ${year} ` }}
      <a href="https://github.com/XVOchuliu" target="_blank">{{ author }}</a>&nbsp;
      <a href="http://beian.miit.gov.cn/" target="_blank">{{ record }}</a>
    </div>
</template>

<script>
  export default {
    name:"App",
    data(){
      return{
        year: new Date().getFullYear(),
        author: 'XVO初六',
        record: '闽ICP备2024040641号'
      }
    }
  }
</script>

<style>
.icp {
	position: absolute;
	bottom: 0;
	right: 0;
	margin: 10px 0;
	width: 100%;
	height: 36px;
	white-space: pre;
	text-align: center;
	color: gray;
	z-index: 1000;
}
.icp > a {
    color: gray;
    text-decoration: none;
}
.icp > a:hover {
    color: aqua;
    text-decoration: none;
}
</style>