import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login'),
    meta: {
      requireAuth: false
    }
  },

  {
    path: '/main',
    name: 'Main',
    component: () => import('../views/Main'),
    redirect: "/home",
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('../views/admin/Home'),
        meta: {
          requireAuth: true, title: '首页'
        }

      },

      {
        path: '/activityAdd',
        name: 'ActivityAdd',
        component: () => import('../views/admin/ActivityAdd'),
        meta: { requiresAuth: true, title: '活动添加' }
      },
      {
        path: '/activityEdit',
        name: 'ActivityEdit',
        component: () => import('../views/admin/ActivityEdit'),
        meta: { requiresAuth: true, title: '活动修改' }
      },
      {
        path: '/activityManage',
        name: 'ActivityManage',
        component: () => import('../views/admin/ActivityManage'),
        meta: { requiresAuth: true, title: '活动管理' }
      },
      {
        path: '/activityDetail',
        name: 'ActivityDetail',
        component: () => import('../views/admin/ActivityDetail'),
        meta: { requiresAuth: true, title: '活动详情' }
      },
      {
        path: '/bannerAdd',
        name: 'BannerAdd',
        component: () => import('../views/admin/BannerAdd'),
        meta: { requiresAuth: true, title: '轮播图添加' }
      },
      {
        path: '/bannerEdit',
        name: 'BannerEdit',
        component: () => import('../views/admin/BannerEdit'),
        meta: { requiresAuth: true, title: '轮播图修改' }
      },
      {
        path: '/bannerManage',
        name: 'BannerManage',
        component: () => import('../views/admin/BannerManage'),
        meta: { requiresAuth: true, title: '轮播图管理' }
      },
      {
        path: '/environmentAdd',
        name: 'EnvironmentAdd',
        component: () => import('../views/admin/EnvironmentAdd'),
        meta: { requiresAuth: true, title: '环保问题添加' }
      },
      {
        path: '/environmentEdit',
        name: 'EnvironmentEdit',
        component: () => import('../views/admin/EnvironmentEdit'),
        meta: { requiresAuth: true, title: '环保问题修改' }
      },
      {
        path: '/environmentManage',
        name: 'EnvironmentManage',
        component: () => import('../views/admin/EnvironmentManage'),
        meta: { requiresAuth: true, title: '环保问题管理' }
      },
      {
        path: '/environmentDetail',
        name: 'EnvironmentDetail',
        component: () => import('../views/admin/EnvironmentDetail'),
        meta: { requiresAuth: true, title: '环保问题详情' }
      },

      {
        path: '/ordersManage',
        name: 'OrdersManage',
        component: () => import('../views/admin/OrdersManage'),
        meta: { requiresAuth: true, title: '商品订单管理' }
      },
      {
        path: '/ordersDetail',
        name: 'OrdersDetail',
        component: () => import('../views/admin/OrdersDetail'),
        meta: { requiresAuth: true, title: '商品订单详情' }
      },
      {
        path: '/recyclesEdit',
        name: 'RecyclesEdit',
        component: () => import('../views/admin/RecyclesEdit'),
        meta: { requiresAuth: true, title: '完成回收' }
      },
      {
        path: '/recyclesManage',
        name: 'RecyclesManage',
        component: () => import('../views/admin/RecyclesManage'),
        meta: { requiresAuth: true, title: '回收管理' }
      },
      {
        path: '/recyclesManage2',
        name: 'RecyclesManage2',
        component: () => import('../views/admin/RecyclesManage2'),
        meta: { requiresAuth: true, title: '回收列表' }
      },
      {
        path: '/recyclesDetail',
        name: 'RecyclesDetail',
        component: () => import('../views/admin/RecyclesDetail'),
        meta: { requiresAuth: true, title: '回收详情' }
      },
      {
        path: '/productAdd',
        name: 'ProductAdd',
        component: () => import('../views/admin/ProductAdd'),
        meta: { requiresAuth: true, title: '积分商品添加' }
      },
      {
        path: '/productEdit',
        name: 'ProductEdit',
        component: () => import('../views/admin/ProductEdit'),
        meta: { requiresAuth: true, title: '积分商品修改' }
      },
      {
        path: '/productManage',
        name: 'ProductManage',
        component: () => import('../views/admin/ProductManage'),
        meta: { requiresAuth: true, title: '积分商品管理' }
      },
      {
        path: '/productDetail',
        name: 'ProductDetail',
        component: () => import('../views/admin/ProductDetail'),
        meta: { requiresAuth: true, title: '积分商品详情' }
      },
      {
        path: '/membersEdit',
        name: 'MembersEdit',
        component: () => import('../views/admin/MembersEdit'),
        meta: { requiresAuth: true, title: '用户修改' }
      },
      {
        path: '/membersManage',
        name: 'MembersManage',
        component: () => import('../views/admin/MembersManage'),
        meta: { requiresAuth: true, title: '用户管理' }
      },
      {
        path: '/membersDetail',
        name: 'MembersDetail',
        component: () => import('../views/admin/MembersDetail'),
        meta: { requiresAuth: true, title: '用户详情' }
      },
      {
        path: '/feedbackEdit',
        name: 'FeedbackEdit',
        component: () => import('../views/admin/FeedbackEdit'),
        meta: { requiresAuth: true, title: '反馈修改' }
      },
      {
        path: '/feedbackManage',
        name: 'FeedbackManage',
        component: () => import('../views/admin/FeedbackManage'),
        meta: { requiresAuth: true, title: '反馈管理' }
      },
      {
        path: '/feedbackDetail',
        name: 'FeedbackDetail',
        component: () => import('../views/admin/FeedbackDetail'),
        meta: { requiresAuth: true, title: '反馈详情' }
      },
      {
        path: '/knowledgeAdd',
        name: 'KnowledgeAdd',
        component: () => import('../views/admin/KnowledgeAdd'),
        meta: { requiresAuth: true, title: '知识宣传添加' }
      },
      {
        path: '/knowledgeEdit',
        name: 'KnowledgeEdit',
        component: () => import('../views/admin/KnowledgeEdit'),
        meta: { requiresAuth: true, title: '知识宣传修改' }
      },
      {
        path: '/knowledgeManage',
        name: 'KnowledgeManage',
        component: () => import('../views/admin/KnowledgeManage'),
        meta: { requiresAuth: true, title: '知识宣传管理' }
      },
      {
        path: '/knowledgeDetail',
        name: 'KnowledgeDetail',
        component: () => import('../views/admin/KnowledgeDetail'),
        meta: { requiresAuth: true, title: '知识宣传详情' }
      },

      {
        path: '/password',
        name: 'Password',
        component: () => import('../views/admin/Password'),
        meta: {
          requireAuth: true, title: '修改密码'
        }
      },
    ]
  },



]



const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})



router.beforeEach((to, from, next) => {
  if (to.path == '/') {
    sessionStorage.removeItem('userLname');
    sessionStorage.removeItem('role');
  }
  let currentUser = sessionStorage.getItem('userLname');
  console.log(to + "  to.meta.requireAuth");

  if (to.meta.requireAuth) {
    if (!currentUser && to.path != '/login') {
      next({ path: '/' });
    } else {
      next();
    }
  } else {

    next();
  }
})

export default router


